.writeDoc {
    width: 91%;
    height: auto;
    text-align: left;
    background-color: #FFFFFF;
    margin: 30px 30px 15px 30px;
    padding: 30px 30px 5px 30px;
}
.writeDoc-inputTextWrite {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    float: left;
}
.writeDoc-btnOrange {
    width: 250px;
    height: 40px;
    background-color: #ff9d00;
    color: white;
    padding: 1px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 15px;
    font-weight: bold;
}
.writeDoc-btnOrange:hover {
    background-color: #FF8C00;
}
.writeDoc-inputTextWriteForImage {
    width: 50%;
    padding: 5px 20px;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    float: left;
    height: 90px;
    text-align: left;
    line-height: 25px;
    overflow: auto;
}
.writeDoc-file-upload {
    position: relative;
    overflow: hidden;
    width: 250px;
    height: 22px;
    background: #228B22;
    border-radius: 3px;
    padding: 8px 4px;
    color: #fff;
    text-align: center;
    top: 25px;
    left: 10px;
}
.writeDoc-file-upload:hover {
    background: #7aad55;
}
.writeDoc-file-upload input[type="file"]{
    display: none;
}
.writeDoc-file-upload label {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}
.writeDoc-file-upload span {
    line-height: 36px;
    color: #fff;
}
.writeDoc-line {
    width: 100%;
}
.writeDoc-line-admin {
    width: 100%;
    text-align: center;
    padding-bottom: 10px;
}
.writeDoc-line-admin-links {
    width: 10%;
    display:inline;
    padding-right: 15px;
}
.writeDoc-line-settings {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
}
.writeDoc-line-submit-button {
    width: 100%;
    text-align: center;
    padding-top: 20px;
}
.writeDoc-div-select {
    width: 30%;
    display:inline;
    padding-right: 20px;
}
.writeDoc-div-dateTimePicker {
    width: 40%;
    display:inline;
    padding-top: 3px;
}
.writeDoc-select {
    width: 100%;
}
.writeDoc-a{
    color: #4169E1;
    text-decoration: none;
}