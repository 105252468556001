.admin{
    padding: 10px;
    width: 95%;
    text-align: center;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    background-color: #FFFFFF;
}
.admin-a{
    color: #4169E1;
    text-decoration: none;
}
.admin-btnOrange {
    width: 250px;
    height: 40px;
    background-color: #ff9d00;
    color: white;
    padding: 1px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 15px;
    font-weight: bold;
}

.admin-btnOrange:hover {
    background-color: #FF8C00;
}