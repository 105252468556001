.listOfDocs{
    padding: 10px;
    width: 95%;
    text-align: left;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    background-color: #FFFFFF;
    font-family: -apple-system,'BlinkMacSystemFont','Segoe UI','Roboto','Helvetica Neue','Arial','Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
}
.listOfDocs-main{
    width: 100%;
    padding: 0px 0px 0px 40px;
    display: flex;
    font-size: 25px;
    font-weight: bold;
}
.listOfDocs-title{
    width: 100%;
    padding: 0px 0px 7px 0px;
    display: flex;
    font-size: 17px;
}
.listOfDocs-a{
    color: #4169E1;
    text-decoration: none;
}
.listOfDocs-line-page {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
}

.listOfDocs-line-settings {
    width: 100%;
    padding-top: 2px;
    padding-bottom: 2px;
    display: flex;
}

.listOfDocs-div-edit {
    width: 12%;
    display:inline;
    padding-top: 3px;
}

.listOfDocs-div-select {
    width: 15%;
    display:inline;
    padding-right: 20px;
}

.listOfDocs-select {
    width: 100%;
}

.listOfDocs-inputTextAdminCount {
    width: 80px;
    padding: 8px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}