@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;700&display=swap');

:root{
    --mainColor: #343a40;
    --mainColorLight: #2D2929;
    --textColor: #CBC5C5;
    --iconColor: #FFFFFF;
}

header{
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    height: 60px;
    padding: 0 2rem;
    background-color: var(--mainColor);
    color: var(--textColor);
    box-sizing: border-box;
    font-family: 'Titillium Web', sans-serif;
}

h3 {
    color: var(--iconColor);
}

h3 a{
    color: var(--iconColor);
    text-decoration: none;
}

nav a{
    margin: 0 2rem;
    padding-top: 0rem;
    color: #f0f0f0;
    text-decoration: none;
}

nav a:hover{
    color: #ff4f45;
}

header .nav-btn {
    padding: 5px;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    color: var(--textColor);
    visibility: hidden;
    opacity: 0;
    font-size: 1.8rem;
}

.nav-icon {
    margin-right: 10px;
}

@media only screen and (max-width: 1024px){
    header .nav-btn {
        visibility: visible;
        opacity: 1;
    }

    header nav {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;
        background-color: var(--mainColor);
        transition: 1s;
        transform: translateY(-100vh);
        z-index: 999999;
    }

    header .responsive_nav {
        transform: none;
    }

    nav .nav-close-btn {
        position: absolute;
        top: 2rem;
        right: 2rem;
    }

    nav a{
        font-size: 1.5rem;
    }
}