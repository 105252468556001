.blog-container {
    width: 100%;
    float: left;
    height: auto;
    background-color: #e4e4e4;
    display: flex;
}
.blog-wrap {
    background-color: #FFFFFF;
    float:left;
    width: 99%;
    height: auto;
    margin: 10px 10px 0px 10px;
    padding: 10px;
    font-family: -apple-system,'BlinkMacSystemFont','Segoe UI','Roboto','Helvetica Neue','Arial','Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
}
.blog-title{
    padding: 10px;
    width: 75%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    font-size: 40px;
    font-weight: bold;
}
.blog-tags{
    padding: 1px;
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
}
.blog-tags-list{
    display:inline;
    color: darkgray;
    max-width: 75%;
}
.blog-tags-info{
    display:inline;
    padding-left: 20px;
    color: darkgray;
}
.blog-tags-name{
    color: #EF5A0F;
    font-weight: bold;
    display:inline;
    padding-left: 5px;
}
.blog-mainImageDiv{
    width: 75%;
    text-align: left;
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
}
.blog-mainImage{
    width: 100%;
}
.blog-textDiv{
    padding: 10px;
    width: 75%;
    height: auto;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
}
.blog-text{
    float: left;
    color: #000000;
    font-size: 18px;
    padding-top: 1px;
    width: 100%;
    height: auto;
    line-height: 1.5;
    word-break: break-word;
}
.blog-author-container {
    width: 75%;
    position: relative;
    display: flex;
    align-items: center;
    margin: auto;
}
.blog-author {
    width: 100%;
    padding: 10px 20px 10px 20px;
    margin: 5px 5px 5px 5px;
    text-align: center;
    display: flex;
    background-color: #FFFFFF;
    border-radius: 10px;
    box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 12%);
}
.blog-author-description-price-count{
    width: 170px;
    height: auto;
    text-align: left;
    margin-left: 10px;
}
.blog-author-description-name{
    font-size: 24px;
}
.blog-author-description-price-icon{
    margin-left: 1px;
    height: 18px;
}
@media only screen and (max-width: 750px){
    .blog-wrap {
        margin: 20px 10px 0px 10px;
        padding: 10px;
        width: 90%;
    }
    .blog-title{
        width: 99%;
    }
    .blog-tags{
        width: 99%;
    }
    .blog-mainImageDiv{
        width: 99%;
    }
    .blog-textDiv{
        width: 99%;
    }
    .blog-author-container{
        width: 98%;
    }
    .blog-author {
        width: 98%;
        padding: 5px 5px 5px 5px;
        margin: 5px 5px 5px 5px;
        text-align: center;
        display: flex;
    }
    .blog-author-description-price-count{
        font-size: 20px;
        width: 99%;
        text-align: left;
        margin-left: 20px;
    }
    .blog-author-description-price-icon{
        margin-left: 1px;
        height: 14px;
    }
    .blog-author-description-name{
        font-size: 20px;
    }
    .blog-title{
        font-size: 22px;
    }
    .blog-tags-list{
        font-size: 13px;
    }
    .blog-tags-info{
        font-size: 13px;
    }
    .blog-tags-name{
        font-size: 13px;
    }
    .blog-text{
        font-size: 15px;
    }
}
@media screen and (min-width: 751px) and (max-width: 1000px) {
    .blog-author-container {
        width: 98%;
    }
    .home-author-description-price-count{
        width: 190px;
    }
    .blog-title{
        width: 99%;
    }
    .blog-mainImageDiv{
        width: 99%;
    }
    .blog-tags{
        width: 99%;
    }
    .blog-textDiv{
        width: 99%;
    }
    .blog-author-description-price-count{
        width: 180px;
    }
}

@media screen and (min-width: 1001px) and (max-width: 1300px) {
    .blog-author-container {
        width: 75%;
    }
    .home-author-description-price-count{
        width: 220px;
    }
    .blog-title{
        width: 99%;
    }
    .blog-mainImageDiv{
        width: 99%;
    }
    .blog-tags{
        width: 99%;
    }
    .blog-textDiv{
        width: 99%;
    }
    .blog-author-description-price-count{
        width: 220px;
    }
}
