#main-slider-container{
    width: 98%;
    height: 305px;
    position: relative;
    display: flex;
    align-items: center;
    margin: auto;
}

#main-slider-container-tags{
    width: 98%;
    height: 50px;
    position: relative;
    display: flex;
    align-items: center;
    margin: auto;
    padding-top: 20px;
}

.main-grid-container{
    width: 98%;
    height: auto;
    position: relative;
    display: flex;
    align-items: center;
    margin: 10px;
}

.main-grid-container-row{
    width: 95%;
    height: auto;
    position: relative;
    display: inline;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

.slider {
    width: 100%;
    height: 100%;
    white-space: nowrap;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    scroll-behavior: smooth;
}

.slider-tag {
    width: 100%;
    height: 100%;
    text-align: center;
    white-space: nowrap;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    scroll-behavior: smooth;
    overflow: hidden;
}

.slider::-webkit-scrollbar {
    display: none;
}

.slider-icon.left, .slider-icon.right{
    background: white;
    border-radius: 100%;
    position: absolute;
    size: 40px;
    opacity: 0.5;
    box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 12%);
    cursor: pointer;
}

.slider-icon.left{
    left: 0;
}

.slider-icon.right{
    right: 0;
}

.slider-icon.left:hover, .slider-icon.right:hover {
    opacity: 1;
}

.slider-card {
     width: 320px;
     height: 300px;
     background: white;
     border-radius: 10px;
     display: inline-block;
     margin-left: 5px;
     margin-right: 5px;
     box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 12%);
     cursor: pointer;
     overflow-x:hidden;
 }

.slider-card-tags {
    min-width: 12px;
    height: 42px;
    background-color: rgb(240 240 240 / 80%);
    border-radius: 10px;
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
    padding-left: 15px;
    padding-right: 15px;
    box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 12%);
    cursor: pointer;
    overflow-x:hidden;
}

.grid-card {
    width: 320px;
    height: 300px;
    background: white;
    border-radius: 10px;
    display: inline-block;
    margin: 5px 5px 5px 5px;
    box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 12%);
    cursor: pointer;
    overflow-x:hidden;
    white-space: nowrap;
}

.slider-card-image {
    width: 100%;
    height: 220px;
    background-color: rgb(240 240 240 / 80%);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.slider-card-title, .slider-card-description {
    margin: 5px 0px 5px 10px;
}

.slider-card-title {
   font-weight: 500;
    margin-top: 10px;
}

.slider-card-description {
    opacity: 0.5;
    font-size: 13px;
}

.slider-card-title-tags {
    margin: 10px 0px 0px 0px;
    font-size: 18px;
}