.profile-container {
    width: 100%;
    float: left;
    height: auto;
    background-color: #e4e4e4;
    display: flex;
}
.profile-wrap {
    background-color: #FFFFFF;
    float:left;
    height: auto;
    margin: 30px 30px 0px 30px;
    padding: 10px;
    font-family: -apple-system,'BlinkMacSystemFont','Segoe UI','Roboto','Helvetica Neue','Arial','Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
}
.profile-textDiv{
    padding: 10px;
    width: 75%;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    display: flex;
}
.profile-topDiv{
    padding: 10px;
    width: 75%;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    display: flex;
}
.profile-priceDiv{
    padding: 10px;
    width: 75%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-top: 0px;
}
.profile-price-form{
    width: 75%;
    border: solid 1px #ff4f45;
    background-color: #FFFFFF;
    height: 480px;
    text-align: center;
}
.profile-text{
    width: 70%;
    float: left;
    color: #3d3d3d;
    font-size: 18px;
    padding-top: 1px;
    height: auto;
    line-height: 1.5;
    word-break: break-word;
    margin-right: 40px;
}
.profile-img{
    width: 30%;
    float: left;
    color: #000000;
    font-size: 18px;
    padding-top: 1px;
    height: auto;
    line-height: 1.5;
    word-break: break-word;
}
.profile-text-title{
    font-size: 35px;
    font-weight: bold;
}
.profile-topicDiv{
    padding: 10px;
    width: 75%;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    display: flex;
}
.profile-topicDiv-sub{
    padding: 10px;
    width: 100%;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    display: inline;
}
.profile-topic-title{
    width: 100%;
    text-align: center;
    height: auto;
    font-size: 35px;
    font-weight: bold;
    color: #3d3d3d;
}
.profile-topic-text{
    font-size: 18px;
    height: auto;
    margin-left: 25%;
}
.profile-text-a{
    color: #ff4f45;
    text-decoration: none;
    font-weight: bold;
}
.profile-col-a{
    color: #3d3d3d;
    text-decoration: none;
    font-weight: bold;
}
.profile-text-p{
    padding-top: 40px;
    text-align: center;
}
.profile-col {
    width: 30%;
    text-align: center;
    float: left;
    color: #000000;
    font-size: 18px;
    padding-top: 1px;
    height: auto;
    line-height: 1.5;
    word-break: break-word;
    margin: 50px 50px 50px 0px;
}
.profile-col-text {
    font-size: 35px;
    font-weight: bold;
}
.profile-author-description-price-time{
    width: 100%;
    display: flex;
}
.profile-author-description-price-time-count{
    width: 60%;
    height: auto;
    font-size: 15px;
    color: dimgrey;
    padding-top: 25px;
    text-align: right;
}
.profile-author-description-price-time-icons-zoom{
    height: auto;
    padding-top: 15px;
    text-align: left;
    padding-left: 5px;
}
.profile-author-description-price-time-icons-skype{
    height: auto;
    padding-top: 22px;
    text-align: left;
}
.profile-author-description-price-time-icons-meet{
    height: auto;
    padding-top: 24px;
    text-align: left;
    padding-left: 0px;
}
.profile-author-description-price-count{
    width: 100%;
    font-size: 40px;
    color: #3d3d3d;
    font-weight: bold;
    height: auto;
}
.profile-author-description-price-payment-methods{
    width: 100%;
    font-size: 40px;
    color: #3d3d3d;
    font-weight: bold;
    height: auto;
    padding-top: 20px;
}
.profile-author-button{
    margin-top: 30px;
    color: black;
}
.profile-author-description-contact{
    margin-top: 10px;
}
.profile-author-description-contact-icon{
    color: dimgrey;
    margin-right: 10px;
}
.profile-author-description-price-icon{
    margin-left: -3px;
    height: 23px;
}
.profile-btn-main {
    background: #ff4f45;
    background-image: -webkit-linear-gradient(top, #ff4f45, #ff4f45);
    background-image: -moz-linear-gradient(top, #ff4f45, #ff4f45);
    background-image: -ms-linear-gradient(top, #ff4f45, #ff4f45);
    background-image: -o-linear-gradient(top, #ff4f45, #ff4f45);
    background-image: linear-gradient(to bottom, #ff4f45, #ff4f45);
    -webkit-border-radius: 78px;
    -moz-border-radius: 78px;
    border-radius: 78px;
    font-family: Arial;
    color: #ffffff;
    font-size: 20px;
    padding: 20px 50px 20px 50px;
    text-decoration: none;
}

.profile-btn-main:hover {
    background: #ff4f35;
    background-image: -webkit-linear-gradient(top, #ff4f35, #ff4f35);
    background-image: -moz-linear-gradient(top, #ff4f35, #ff4f35);
    background-image: -ms-linear-gradient(top, #ff4f35, #ff4f35);
    background-image: -o-linear-gradient(top, #ff4f35, #ff4f35);
    background-image: linear-gradient(to bottom, #ff4f35, #ff4f35);
    text-decoration: none;
}

.profile-btn {
    background: #ff4f45;
    background-image: -webkit-linear-gradient(top, #ff4f45, #ff4f45);
    background-image: -moz-linear-gradient(top, #ff4f45, #ff4f45);
    background-image: -ms-linear-gradient(top, #ff4f45, #ff4f45);
    background-image: -o-linear-gradient(top, #ff4f45, #ff4f45);
    background-image: linear-gradient(to bottom, #ff4f45, #ff4f45);
    -webkit-border-radius: 78px;
    -moz-border-radius: 78px;
    border-radius: 78px;
    font-family: Arial;
    color: #FFFFFF;
    font-size: 20px;
    padding: 20px 90px 20px 90px;
    text-decoration: none;
    cursor: pointer;
}

.profile-btn:hover {
    background: #ff4f35;
    background-image: -webkit-linear-gradient(top, #ff4f35, #ff4f35);
    background-image: -moz-linear-gradient(top, #ff4f35, #ff4f35);
    background-image: -ms-linear-gradient(top, #ff4f35, #ff4f35);
    background-image: -o-linear-gradient(top, #ff4f35, #ff4f35);
    background-image: linear-gradient(to bottom, #ff4f35, #ff4f35);
    text-decoration: none;
    cursor: pointer;
}

.profile-inputTextWrite-div {
    width: auto;
    height: auto;
}

.profile-btn-div {
    width: auto;
    height: auto;
    padding-top: 50px;
}

.profile-inputTextWrite {
    width: 350px;
    padding: 12px 20px;
    margin: 8px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 16px;
}

.profile-payDiv{
    padding: 10px;
    width: 75%;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    display: flex;
}
.profile-payDiv-sub{
    padding: 10px;
    width: 100%;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    display: inline;
}
.profile-pay-title{
    width: 100%;
    text-align: center;
    height: auto;
    font-size: 35px;
    font-weight: bold;
    color: #3d3d3d;
}
.profile-pay-text{
    font-size: 18px;
    height: auto;
    margin-left: 25%;
    display: flex;
    padding-top: 40px;
}
.profile-contactsDiv{
    padding: 10px;
    width: 75%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-top: 0px;
}
.profile-contactsDiv-sub{
    padding: 10px;
    width: 100%;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    display: inline;
}
.profile-contacts-title{
    width: 100%;
    text-align: center;
    height: auto;
    font-size: 35px;
    font-weight: bold;
    color: #3d3d3d;
}
.profile-contacts-text{
    width: 100%;
    text-align: center;
    height: auto;
    font-size: 18px;
    margin-left: 30%;
}
.profile-social-col {
    width: 10%;
    text-align: center;
    float: left;
    color: #000000;
    font-size: 18px;
    padding-top: 1px;
    height: auto;
    line-height: 1.5;
    word-break: break-word;
    margin: 50px 10px 50px 0px;
}
.circle-icon {
    color: #ff4f45;
}
.contact-icon {
    color: #ff4f45;
    margin-left: 5px;
    margin-right: 5px;
}
.payment-icon {
    color: #ff4f45;
    margin-left: 20px;
}
.profile-hr {
    border-top: 1px solid #ff4f45;
}
.profile-showSuccessResult {
    display: none;
    font-size: 20px;
    color: #7aad55;
    margin: 0px 10px 0px 10px;
}
.profile-contact-icon{
    color: dimgrey;
    margin-right: 10px;
    width: 70px;
    height: 70px;
}
@media only screen and (max-width: 1024px){
    .profile-wrap {
        margin: 10px 10px 1px 10px;
        padding: 10px 15px 0px 0px;
        width: 99%;
    }
    .profile-textDiv{
        display: grid;
        width: 100%;
    }
    .profile-text{
        width: 99%;
    }
    .profile-img{
        width: 99%;
        text-align: center;
    }
    .profile-topDiv{
        display: grid;
        width: 100%;
    }
    .profile-col {
        width: 99%;
        margin: 0px 0px 30px 0px
    }
    .profile-topic-text{
        margin-left: 0%;
    }
    .profile-topicDiv{
        display: grid;
        width: 100%;
    }
    .profile-priceDiv{
        display: grid;
        width: 100%;
    }
    .profile-pay-text{
        margin: 0px 12px 0px 10px;
    }
    .profile-price-form{
        width: 99%;
        height: 450px;
    }
    .profile-contactsDiv{
        width: 100%;
    }
    .profile-social-col {
        width: 20%;
        margin: 20px 10px 20px 0px
    }
    .profile-contacts-text{
        width: 100%;
        text-align: center;
        margin-left: 10px;
    }
    .profile-contact-icon{
        width: 55px;
        height: 55px;
        margin-right: 5px;
    }
    .profile-topicDiv-sub{
        padding: 1px;
    }
    .profile-inputTextWrite{
        width: 85%;
    }
    .profile-text-title{
        font-size: 30px;
    }
    .profile-topic-title{
        font-size: 30px;
    }
    .profile-pay-title{
        font-size: 30px;
    }
    .profile-col-text{
        font-size: 30px;
    }
    .profile-author-description-price-count{
        font-size: 30px;
        padding-top: 10px;
    }
    .profile-payDiv-sub{
        padding: 1px;
    }
    .profile-author-description-price-time-count{
        width: 50%;
    }
    .profile-author-description-price-payment-methods{
        padding-top: 10px;
    }
    .profile-author-button{
        margin-top: 20px;
    }
}