html {
  scroll-behavior: smooth;
}

body {
  background-color: #e4e4e4;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

#body {
  width: 100%;
  justify-content: center;
  align-items: center;
}

.label{
  padding: 0px 20px 0px 20px;
  width: 98%;
  display: flex;
}
.labelLeft{
  width: 50%;
  text-align: left;
}
.labelRight{
  width: 50%;
  text-align: right;
  margin-right: 15px;
}
.labelA{
  text-decoration: none;
  color: black;
}

.home-author-container {
  width: 60%;
  position: relative;
  display: flex;
  align-items: center;
  margin: auto;
  font-family: -apple-system,'BlinkMacSystemFont','Segoe UI','Roboto','Helvetica Neue','Arial','Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
}
.home-author {
  width: 100%;
  padding: 10px 20px 10px 20px;
  margin: 40px 5px 5px 5px;
  text-align: center;
  display: flex;
  background-color: #FFFFFF;
  border-radius: 10px;
  box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 12%);
}
.home-author-img{
  width: 150px;
  display:inline;
  padding-top: 3px;
}
.home-author-img-mobile{
  display: none;
}
.home-author-description{
  width: 100%;
  display:inline;
  padding-left: 20px;
  text-align: left;
}
.home-author-description-price-block{
  display: flex;
  width: 100%;
}
.home-author-description-price{
  width: 100%;
  font-size: 22px;
  color:  #ff4f45;
  font-weight: bold;
  display: flex;
}
.home-author-description-price-time{
  width: 100%;
  height: auto;
  font-size: 15px;
  color: dimgrey;
  padding-top: 3px;
  text-align: right;
}
.home-author-description-price-count{
  width: 190px;
  height: auto;
  text-align: left;
  margin-left: 10px;
}
.home-author-description-contact{
  margin-top: 10px;
}
.home-author-description-contact-icon{
  color: dimgrey;
  margin-right: 10px;
}
.home-author-description-price-icon{
  margin-left: 1px;
  height: 18px;
}
.home-author-description-author{
  font-size: 16px;
}
.home-author-description-name{
  font-size: 24px;
}
.home-author-description-info{
  width: 100%;
  font-size: 16px;
  display: flex;
}
.home-author-description-info-desc{
  width: 70%;
}
.home-author-description-info-pay{
  width: 30%;
  text-align: right;
}

.author-btn {
  background: #ff4f45;
  background-image: -webkit-linear-gradient(top, #ff4f45, #ff4f45);
  background-image: -moz-linear-gradient(top, #ff4f45, #ff4f45);
  background-image: -ms-linear-gradient(top, #ff4f45, #ff4f45);
  background-image: -o-linear-gradient(top, #ff4f45, #ff4f45);
  background-image: linear-gradient(to bottom, #ff4f45, #ff4f45);
  -webkit-border-radius: 78px;
  -moz-border-radius: 78px;
  border-radius: 78px;
  font-family: Arial;
  color: #FFFFFF;
  font-size: 18px;
  padding: 16px 40px 16px 40px;
  text-decoration: none;
}

.author-btn:hover {
  background: #ff4f35;
  background-image: -webkit-linear-gradient(top, #ff4f35, #ff4f35);
  background-image: -moz-linear-gradient(top, #ff4f35, #ff4f35);
  background-image: -ms-linear-gradient(top, #ff4f35, #ff4f35);
  background-image: -o-linear-gradient(top, #ff4f35, #ff4f35);
  background-image: linear-gradient(to bottom, #ff4f35, #ff4f35);
  text-decoration: none;
}

@media only screen and (max-width: 750px){
  .home-author-container {
    width: 98%;
  }
  .home-author {
    width: 87%;
  }
  .home-author-description{
    width: 100%;
  }
  .home-author-img{
    display: none;
  }
  .home-author-img-mobile{
    width: 30%;
    display:inline;
    padding-top: 3px;
  }
  .home-author-description-price-icon{
    margin-left: 1px;
    height: 14px;
  }
  .home-author-description-price-block{
    display: inline;
    width: 70%;
  }
  .home-author-description-price-time{
    font-size: 18px;
    padding-top: 20px;
    margin-left: 20px;
    width: 99%;
    text-align: left;
  }
  .home-author-description-price-count{
    font-size: 20px;
    width: 99%;
    text-align: left;
    margin-left: 20px;
  }
  .home-author-description-info{
    display: grid;
  }
  .home-author-description-info-desc{
    width: 99%;
  }
  .home-author-description-info-pay{
    width: 99%;
    margin: 25px 0px 20px 0px;
    text-align: center;
  }
  .home-author-description-contact{
    text-align: center;
  }
  .home-author-description-contact-icon{
    margin-right: 20px;
  }
  .author-btn {
    font-size: 16px;
    padding: 14px 35px 14px 35px;
  }
}

@media screen and (min-width: 751px) and (max-width: 1000px) {
  .home-author-container {
    width: 98%;
  }
  .home-author-description-price-count{
    width: 190px;
  }
}

@media screen and (min-width: 1001px) and (max-width: 1300px) {
  .home-author-container {
    width: 75%;
  }
  .home-author-description-price-count{
    width: 220px;
  }
}