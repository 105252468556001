.login {
    width: 95%;
    height: auto;
    background-color: #FFFFFF;
    margin: 30px;
    text-align: center;
}
.login-form-group{
    width: 100%;
    height: auto;
    padding: 20px 0px 0px 0px;
    text-align: center;
}
.login-form-group-button{
    width: 100%;
    height: auto;
    padding: 20px 0px 20px 0px;
    text-align: center;
}
.login-inputTextWrite {
    width: 350px;
    padding: 12px 20px;
    margin: 8px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}
.login-btnOrange {
    width: 250px;
    height: 40px;
    background-color: #ff9d00;
    color: white;
    padding: 1px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 15px;
    font-weight: bold;
}

.login-btnOrange:hover {
    background-color: #FF8C00;
}